import { useMutation } from 'react-query';
import { completeTransactionSetup } from 'services/CustomerService';
import { CompleteTransactionSetupPayload } from 'pages/WorldPay/index';
import { useAptiveAuth } from 'shared/hooks/AptiveAuth';
import { AuthType } from '../../../constants/auth';

const useCompleteTransactionSetup = () => {
  const { getIdTokenClaims, getMagicLinkToken, authType } = useAptiveAuth();

  return useMutation(
    async ({
      accountId,
      transactionId,
      payload,
    }: {
      accountId: string;
      transactionId: string;
      payload: CompleteTransactionSetupPayload;
    }) => {
      let idToken;
      if (authType === AuthType.MagicLink) {
        idToken = getMagicLinkToken?.();
      } else {
        idToken = (await getIdTokenClaims?.())?.__raw;
      }
      return completeTransactionSetup(accountId, transactionId, payload, idToken || '', authType);
    }
  );
};

export default useCompleteTransactionSetup;
