import { useAuth0 } from '@auth0/auth0-react';
import { AuthType } from '../../../constants/auth';

export const useAptiveAuth = () => {
  const authType = window.localStorage.getItem('authType') || 'Auth0';
  const { getIdTokenClaims, loginWithRedirect, logout, isAuthenticated, isLoading, user } = useAuth0();

  if (authType === AuthType.MagicLink) {
    const loginWithRedirect = () => {
      window.localStorage.removeItem('authType');
      window.localStorage.removeItem('aptiveUserToken');
      window.location.href = '/';
    };
    const logout = () => {
      window.location.href = '/logout';
      setTimeout(() => {
        window.localStorage.removeItem('authType');
        window.localStorage.removeItem('aptiveUserToken');
      }, 100);
    };

    const getMagicLinkToken = () => window.localStorage.getItem('aptiveUserToken');
    return {
      authType,
      isAuthenticated: true,
      isLoading: false,
      user: {
        email_verified: true,
        email: '',
      },
      getIdTokenClaims: undefined,
      getMagicLinkToken,
      loginWithRedirect,
      logout,
    };
  }
  return { getIdTokenClaims, loginWithRedirect, logout, isAuthenticated, isLoading, user, authType };
};
