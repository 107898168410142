import React, { ComponentType, FC } from 'react';
import { withAuthenticationRequired as withAuthenticationRequiredAuth0 } from '@auth0/auth0-react';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react/src/with-authentication-required';
import { isUsingMagicLinkAuthen } from '../../../helpers';

const withAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {}
): FC<P> => {
  if (isUsingMagicLinkAuthen()) {
    return function WithAuthenticationRequired(props: P): JSX.Element {
      return <Component {...props} />;
    };
  } else {
    return withAuthenticationRequiredAuth0(Component, options);
  }
};

export { withAuthenticationRequired };
