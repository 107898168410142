import React, { useLayoutEffect } from 'react';

import { useTrackingView } from '../shared/hooks/useTrackingView';
import { useAptiveAuth } from '../shared/hooks/AptiveAuth';

const AuthPage: React.FC = () => {
  const { loginWithRedirect } = useAptiveAuth();
  useTrackingView();

  useLayoutEffect(() => {
    loginWithRedirect?.();
  }, []);

  return null;
};

export default AuthPage;
