import axios, { AxiosInstance } from 'axios';
import { WebAuth } from 'auth0-js';

const auth0 = new WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  redirectUri: `${window.location.origin}`,
});

export const baseURL = process.env.REACT_APP_API_URL || 'http://localhost/api/v1';
const aptiveAPI: AxiosInstance = axios.create({
  baseURL,
});

let requestInterceptor: number;
let persistToken: string;
type ConfigRequestTokenProps = { newToken?: string; authType?: string };
export const configRequestToken = ({ newToken, authType }: ConfigRequestTokenProps = {}) => {
  if (requestInterceptor) {
    aptiveAPI.interceptors.request.eject(requestInterceptor);
  }

  if (newToken) {
    persistToken = newToken;
  }

  requestInterceptor = aptiveAPI.interceptors.request.use(
    config => {
      let token = null;
      if (persistToken) {
        token = persistToken;
      } else {
        token = localStorage.getItem('aptiveUserToken')?.replace(/['"]+/g, '') || null;
      }

      if (config.headers && token !== 'null' && token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (config.headers && authType) {
        config.headers['X-Auth-Type'] = authType;
      }

      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );
};

aptiveAPI.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error?.response?.status;
    const redirectOnInternalErr = error?.response?.config?.headers?.['_client_internal_err_redirect'] !== false;

    if (status === 403 || status === 401) {
      sessionStorage.removeItem('aptiveUserAccounts');
      localStorage.removeItem('aptiveUserToken');
      sessionStorage.removeItem('aptiveUserData');
      sessionStorage.removeItem('aptiveUser');

      auth0.checkSession(
        {
          responseType: 'token id_token',
          scope: 'openid profile email email_verified',
        },
        (err: any, result: any) => {
          if (err) {
            auth0.logout({
              returnTo: '/',
            });
          } else {
            // Check if the user's email is verified
            if (!result.idTokenPayload.email_verified) {
              window.location.href = '/not-verified';
            } else {
              auth0.logout({
                returnTo: '/',
              });
            }
          }
        }
      );
    }

    if (status === 412) {
      window.location.href = '/not-found';
    }

    if (status === 429) {
      window.location.href = '/server-error?error=429';
    }

    if (status === 500 && redirectOnInternalErr) {
      window.location.href = '/server-error';
    }

    return Promise.reject(error);
  }
);

export default aptiveAPI;
