import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from 'config';
import { MyAccountRoot } from 'routes/my-account';
import { createBrowserHistory } from 'history';
import React from 'react';
import { isUsingMagicLinkAuthen } from '../../../helpers';

const config = getConfig();

const onRedirectCallback = (appState: any) => {
  createBrowserHistory().push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: `${window.location.origin}${MyAccountRoot.path}`,
  onRedirectCallback,
};

export const AptiveAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  if (isUsingMagicLinkAuthen()) {
    return <>{children}</>;
  }
  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};
